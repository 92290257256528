import { GlobalStyles } from '@mui/material'
import { DefaultSeo } from 'next-seo'

import 'aos/dist/aos.css'
import 'material-symbols/rounded.css'
import type { AppProps } from 'next/app'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const { url, siteName, title, description, type } = {
  url: 'mangomap.com',
  siteName: 'Mango GIS',
  title: 'Simple, professional online GIS maps',
  description:
    'The accessible and user-friendly alternative to Esri for smart spenders. Create and securely share maps your way.',
  type: 'website'
}

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <DefaultSeo
        title={title}
        description={description}
        themeColor='#ff5722'
        canonical='https://mangomap.com'
        robotsProps={{
          maxSnippet: -1,
          maxImagePreview: 'large',
          maxVideoPreview: -1
        }}
        additionalMetaTags={[
          {
            name: 'application-name',
            content: 'Mango GIS'
          },
          {
            name: 'apple-mobile-web-app-title',
            content: 'Mango GIS'
          },
          {
            name: 'format-detection',
            content: 'telephone=no'
          },
          {
            name: 'msapplication-TileColor',
            content: '#FF5621'
          },
          {
            name: 'msapplication-tap-highlight',
            content: 'no'
          }
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico'
          },
          {
            rel: 'apple-touch-icon',
            href: '/icons/apple-touch-icon.png',
            sizes: '180x180'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/icons/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/icons/favicon-16x16.png'
          },
          {
            rel: 'manifest',
            href: '/manifest.json'
          },
          {
            rel: 'preconnect',
            href: 'https://fonts.googleapis.com'
          },
          {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com'
          },
          {
            rel: 'preconnect',
            href: 'https://dev.visualwebsiteoptimizer.com'
          }
        ]}
        openGraph={{
          type,
          url,
          title,
          description:
            'Create and securely share maps your way. The accessible and user-friendly alternative to Esri for smart spenders.',
          siteName,
          locale: 'en_GB',
          images: [
            {
              url: 'new-mangomap-assets/images/mangoGIS.png',
              width: 1182,
              height: 670,
              alt: 'Mango GIS',
              type: 'image/png'
            }
          ]
        }}
      />
      <GlobalStylesOverride />
      <Component {...pageProps} />
    </>
  )
}

export default App

const GlobalStylesOverride = () => {
  return (
    <GlobalStyles
      styles={{
        'html, body': {
          padding: 0,
          margin: 0,
          fontFamily: `'Inter', sans-serif`
        },

        '*': {
          msOverflowStyle: 'none',
          boxSizing: 'border-box'
        },
        material_symbols_rounded: {
          fontVariationSettings: `'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 40`
        },
        'span.material-symbols-h3': {
          fontSize: '48px !important'
        },
        'span.material-symbols-h4': {
          fontSize: '28px !important'
        },
        'span.material-symbols-h6': {
          fontSize: '18px !important'
        }
      }}
    />
  )
}
